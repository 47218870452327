
import { defineComponent, onMounted, ref, onUpdated } from 'vue';
import { useRoute } from 'vue-router';
import format from 'date-fns/format';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import partnerAPI from '@/service/partnerAPI';
import useUtils from '@/mixins/useUtils';

export default defineComponent({
  name: 'QnA',
  setup() {
    const route = useRoute();
    console.log(route.query.productId, route.query.memberId);
    const productQnaList = ref([]);
    const selectedProductId = ref(undefined);

    const memberQnaList = ref([]);
    const selectedMemberId = ref(undefined);

    const detailQnaList = ref([]);
    const $talkScrollElement = ref(null);
    const $talkListElement = ref(null);
    const { maskingId } = useUtils();

    const fetchProductQnaList = async () => {
      try {
        const { data } = await partnerAPI.partnerQna.listProductUsingGET();
        productQnaList.value = (data as any).data;
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const scrollToEnd = () => {
      const content = $talkScrollElement.value;
      if (content) {
        (content as HTMLElement).scrollTop = (
          content as HTMLElement
        ).scrollHeight;
      }
    };

    const productListActiveItem = () => {
      const activeItem = document.querySelector('.qna-product-item.is--active');
      const top = (activeItem as HTMLElement).offsetTop;
      const listContent = $talkListElement.value;
      if (listContent) {
        (listContent as HTMLElement).scrollTop = top;
      }
    };

    const handleSelectProduct = (productId) => {
      selectedMemberId.value = undefined;
      detailQnaList.value = [];
      inputAnswer.value = '';
      fetchMemberQnaList(productId);
    };

    const fetchMemberQnaList = async (productId) => {
      try {
        const { data } = await partnerAPI.partnerQna.listMemberUsingGET({
          productId,
        });
        memberQnaList.value = (data as any).data;
        selectedProductId.value = productId;
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const handleSelectMember = (memberId) => {
      inputAnswer.value = '';
      fetchDetailQnaList(selectedProductId.value, memberId);
    };

    const fetchDetailQnaList = async (productId, memberId) => {
      try {
        const { data } = await partnerAPI.partnerQna.listDetailUsingGET({
          productId,
          memberId,
        });
        const body = (data as any).data;
        detailQnaList.value = body.map((detail) => {
          return {
            ...detail,
            memo: detail.memo?.replace(/\n/g, '<br/>'),
          };
        });
        selectedMemberId.value = memberId;
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const inputAnswer = ref('');

    const handleSendAnswer = async () => {
      if (!inputAnswer.value) {
        alert('메시지를 입력해 주세요.');
        return;
      }
      try {
        await partnerAPI.partnerQna.addQnaUsingPOST1({
          param: {
            memberId: selectedMemberId.value,
            productId: selectedProductId.value,
            memo: inputAnswer.value,
          },
        });
        inputAnswer.value = '';
        await fetchDetailQnaList(selectedProductId.value, selectedMemberId.value);
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const numberFormat = (num) => {
      return num?.toLocaleString();
    };

    const targetInit = async (initProductId, initMemberId) => {
      await fetchProductQnaList();
      await fetchMemberQnaList(initProductId);
      if (initMemberId) {
        fetchDetailQnaList(initProductId, initMemberId);
      }
      productListActiveItem();
    };

    const defaultInit = async () => {
      await fetchProductQnaList();
      if (productQnaList.value.length > 0) {
        const firstProductId = (productQnaList.value[0] as any).productId;
        await fetchMemberQnaList(firstProductId);
        if (memberQnaList.value.length > 0) {
          const firstMemberId = (memberQnaList.value[0] as any).memberId;
          await fetchDetailQnaList(firstProductId, firstMemberId);
        }
      }
    };

    onMounted(() => {
      const initProductId = Number(route.query.productId);
      const initMemberId = Number(route.query.memberId);
      if (route.query.productId) {
        // 파라미터 링크 대화를 연다
        targetInit(initProductId, initMemberId);
      } else {
        // 첫 대화를 연다.
        defaultInit();
      }
      scrollToEnd();
    });

    onUpdated(() => {
      scrollToEnd();
    });

    return {
      maskingId,
      productQnaList,
      selectedProductId,
      handleSelectProduct,
      memberQnaList,
      selectedMemberId,
      handleSelectMember,
      detailQnaList,
      inputAnswer,
      $talkListElement,
      $talkScrollElement,
      productListActiveItem,
      handleSendAnswer,
      numberFormat,
      format,
    };
  },
});
