<template>
  <div>
    <div class="grid grid-cols-2 gap-2">
      <div class="grid grid-cols-2 gap-2">
        <div class="bg-white qna-content">
          <h2 class="border-b font-semibold px-5 py-7 border-black">
            상품목록
          </h2>
          <ul ref="$talkListElement" class="qna-content-scroll qna-product-list overflow-auto">
            <li
              v-for="(product, index) in productQnaList"
              :key="index"
              class="
                p-4
                border-b border-dashed border-gray-300
                flex
                items-start
                cursor-pointer
                qna-product-item
              "
              :class="[selectedProductId === product.productId && 'is--active']"
              @click="handleSelectProduct(product.productId)"
            >
              <div
                class="w-20 h-20 bg-gray-100 bg-cover flex-none"
                :style="
                  `background-image: url('${product.productImageList[0]?.url || 'https://via.placeholder.com/120x120/F3F4F6?text=x'}');`
                "
              ></div>
              <div class="pl-4 qna-item-text-area">
                <strong class="text-sm font-semibold">
                  {{ product.artistName }}
                </strong>
                <p class="text-xs mb-3 text-gray-900">
                  {{ product.title }}
                </p>
                <p v-if="product.nowPrice" class="text-sm">
                  현재가 : <b class="font-semibold">{{ numberFormat(product.nowPrice) }}</b>원
                </p>
              </div>
              <mark v-if="product.qnaCount > 0" class="badge badge-position">{{ product.qnaCount }}</mark>
            </li>
          </ul>
        </div>
        <div class="bg-white qna-content">
          <h2 class="border-b font-semibold px-5 py-7 border-black">
            문의목록
          </h2>
          <ul class="qna-content-scroll overflow-auto">
            <li
              v-for="(member, index) in memberQnaList"
              :key="index"
              class="
                p-4
                pb-5
                border-b border-dashed border-gray-300
                flex
                items-start
                cursor-pointer
                qna-product-item
              "
              :class="[selectedMemberId === member.memberId && 'is--active']"
              @click="handleSelectMember(member.memberId)"
            >
              <div
                class="w-14 h-14 bg-gray-100 bg-cover profile-img flex-none"
                :style="
                  `background-image: url('${member.profileImgUrl}');`
                "
              ></div>
              <div class="pl-4 w-full">
                <div class="flex items-center justify-between mb-2">
                  <span>
                    <strong class="text-sm font-semibold">
                      {{ maskingId(member.email) }}
                    </strong>
                    <mark v-if="member.type === 'question'" class="badge ml-2">N</mark>
                  </span>

                  <span class="text-xs">
                    {{ format(new Date(member.createdAt), 'hh:mm aa') }}
                  </span>
                </div>
                <p class="text-xs text-gray-600 h-12 text-overflow">
                  {{ member.qnaMemo }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="bg-white qna-content">
        <div class="qna-talk-notice text-sm px-5 py-8 relative">
          대화 내용은 1년간 저장됩니다.
          <div class="qna-talk-notice-layer"></div>
        </div>
        <div class="qna-content-scroll-talk overflow-auto px-5 pt-3 flex flex-col" ref="$talkScrollElement">
          <div
            v-for="(detail) in detailQnaList"
            :key="detail.productQnaId"
            class="qna-talk-content inline-flex mb-6"
            :class="[(detail.type === 'answer') && 'flex-row-reverse']"
          >
            <div
              class="px-4 py-6 text-sm rounded-md qna-talk-content-content"
              :class="[detail.type === 'answer' ? 'green' : 'blue']"
              v-html="detail.memo"
            ></div>
            <p
              class="text-xs text-gray-500"
              :class="[detail.type === 'answer' ? 'mr-3 text-right' : 'ml-3 text-left']"
            >
              {{ format(new Date(detail.createdAt), 'yyyy-MM-dd') }}<br />
              {{ format(new Date(detail.createdAt), 'HH:mm') }}
            </p>
          </div>
        </div>
        <div
          v-if="selectedMemberId"
          class="
            h-22
            flex
            items-center
            px-6
            border-t border-gray-300
            justify-between
          "
        >
          <textarea
            class="resize-none p-2 h-10 text-sm w-full placeholder-gray-400"
            placeholder="메시지를 입력하세요."
            v-model="inputAnswer"
          ></textarea>
          <button
            class="
              flex-none
              w-30
              h-10
              ml-4
              bg-black
              text-white text-xs
              focus:outline-none
            "
            @click="handleSendAnswer"
          >
            전송하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, onUpdated } from 'vue';
import { useRoute } from 'vue-router';
import format from 'date-fns/format';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import partnerAPI from '@/service/partnerAPI';
import useUtils from '@/mixins/useUtils';

export default defineComponent({
  name: 'QnA',
  setup() {
    const route = useRoute();
    console.log(route.query.productId, route.query.memberId);
    const productQnaList = ref([]);
    const selectedProductId = ref(undefined);

    const memberQnaList = ref([]);
    const selectedMemberId = ref(undefined);

    const detailQnaList = ref([]);
    const $talkScrollElement = ref(null);
    const $talkListElement = ref(null);
    const { maskingId } = useUtils();

    const fetchProductQnaList = async () => {
      try {
        const { data } = await partnerAPI.partnerQna.listProductUsingGET();
        productQnaList.value = (data as any).data;
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const scrollToEnd = () => {
      const content = $talkScrollElement.value;
      if (content) {
        (content as HTMLElement).scrollTop = (
          content as HTMLElement
        ).scrollHeight;
      }
    };

    const productListActiveItem = () => {
      const activeItem = document.querySelector('.qna-product-item.is--active');
      const top = (activeItem as HTMLElement).offsetTop;
      const listContent = $talkListElement.value;
      if (listContent) {
        (listContent as HTMLElement).scrollTop = top;
      }
    };

    const handleSelectProduct = (productId) => {
      selectedMemberId.value = undefined;
      detailQnaList.value = [];
      inputAnswer.value = '';
      fetchMemberQnaList(productId);
    };

    const fetchMemberQnaList = async (productId) => {
      try {
        const { data } = await partnerAPI.partnerQna.listMemberUsingGET({
          productId,
        });
        memberQnaList.value = (data as any).data;
        selectedProductId.value = productId;
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const handleSelectMember = (memberId) => {
      inputAnswer.value = '';
      fetchDetailQnaList(selectedProductId.value, memberId);
    };

    const fetchDetailQnaList = async (productId, memberId) => {
      try {
        const { data } = await partnerAPI.partnerQna.listDetailUsingGET({
          productId,
          memberId,
        });
        const body = (data as any).data;
        detailQnaList.value = body.map((detail) => {
          return {
            ...detail,
            memo: detail.memo?.replace(/\n/g, '<br/>'),
          };
        });
        selectedMemberId.value = memberId;
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const inputAnswer = ref('');

    const handleSendAnswer = async () => {
      if (!inputAnswer.value) {
        alert('메시지를 입력해 주세요.');
        return;
      }
      try {
        await partnerAPI.partnerQna.addQnaUsingPOST1({
          param: {
            memberId: selectedMemberId.value,
            productId: selectedProductId.value,
            memo: inputAnswer.value,
          },
        });
        inputAnswer.value = '';
        await fetchDetailQnaList(selectedProductId.value, selectedMemberId.value);
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const numberFormat = (num) => {
      return num?.toLocaleString();
    };

    const targetInit = async (initProductId, initMemberId) => {
      await fetchProductQnaList();
      await fetchMemberQnaList(initProductId);
      if (initMemberId) {
        fetchDetailQnaList(initProductId, initMemberId);
      }
      productListActiveItem();
    };

    const defaultInit = async () => {
      await fetchProductQnaList();
      if (productQnaList.value.length > 0) {
        const firstProductId = (productQnaList.value[0] as any).productId;
        await fetchMemberQnaList(firstProductId);
        if (memberQnaList.value.length > 0) {
          const firstMemberId = (memberQnaList.value[0] as any).memberId;
          await fetchDetailQnaList(firstProductId, firstMemberId);
        }
      }
    };

    onMounted(() => {
      const initProductId = Number(route.query.productId);
      const initMemberId = Number(route.query.memberId);
      if (route.query.productId) {
        // 파라미터 링크 대화를 연다
        targetInit(initProductId, initMemberId);
      } else {
        // 첫 대화를 연다.
        defaultInit();
      }
      scrollToEnd();
    });

    onUpdated(() => {
      scrollToEnd();
    });

    return {
      maskingId,
      productQnaList,
      selectedProductId,
      handleSelectProduct,
      memberQnaList,
      selectedMemberId,
      handleSelectMember,
      detailQnaList,
      inputAnswer,
      $talkListElement,
      $talkScrollElement,
      productListActiveItem,
      handleSendAnswer,
      numberFormat,
      format,
    };
  },
});
</script>

<style lang="scss" scoped>
.search-top {
  top: 0;
  left: 193px;
}
.qna-content {
  height: 1024px;
  .qna-product-item {
    position: relative;
    .qna-item-text-area {
      width: calc(100% - 80px - 50px);
    }
    &:hover,
    &.is--active {
      background: rgba(73, 205, 218, 0.15);
    }
  }
  .profile-img {
    border-radius: 50%;
  }
  .text-overflow {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
    box-sizing: border-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
  &-scroll {
    height: calc(100% - 81px);
    &-talk {
      height: calc(100% - 81px - 88px);
    }
  }
}
.qna-talk-notice {
  position: relative;
  &-layer {
    position: absolute;
    left: 0;
    bottom: -29px;
    width: 100%;
    height: 29px;
    background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}
.qna-talk-content {
  &-content {
    max-width: 480px;
    &.blue {
      background: #e3fafe;
    }
    &.green {
      background: #e5f8d2;
    }
  }
}
.qna-product-list {
  position: relative;
}
.badge {
  background: #F5222D;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #fff;
  padding: 0 8px;
  display: inline-block;
  &-position {
    position: absolute;
    right: 16px;
    top: calc(50% - 8px);
  }
}
</style>
